import React, { createContext, useEffect, useRef, useState } from 'react';
import mqttService from '../pages/loginfolder/mqttService';
import Cookies from 'js-cookie';

const MqttContext = createContext();

const MqttProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const isConnecting = useRef(false); // Use useRef to track connection status

  useEffect(() => {
    const connectToMqtt = async () => {
      const authState = Cookies.get('_auth_state');
      if (authState) {
        const authStateObj = JSON.parse(authState);
        if (authStateObj.access === 'all' && !isConnected && !isConnecting.current) {
          isConnecting.current = true; // Mark as connecting
          console.log("Connecting to MQTT broker...");
          const brokerUrl = 'wss://mqtt.dev.bookukride.co.uk:8083/mqtt';
          const mqttUsername = 'developerTestClient1';
          const mqttPassword = 'yqbSPfsNk8NfQXNC7TwRsuRM';
          const topic = 'MarketingGateway/whatsapp_qr';

          mqttService.connect(brokerUrl, mqttUsername, mqttPassword);
          await new Promise(resolve => setTimeout(resolve, 3000));
          mqttService.subscribe(topic, (receivedTopic, message) => {
            console.log('Received message:', message);
            window.location.href = `/whatsapp_qr/${message}`;
            // window.open(`/whatsapp_qr/${message}`, '_blank');
          });

          setIsConnected(true);
          isConnecting.current = false; // Reset connecting status
        }
      }
    };

    connectToMqtt();
  }, [isConnected]);

  return (
    <MqttContext.Provider value={{}}>
      {children}
    </MqttContext.Provider>
  );
};

export { MqttContext, MqttProvider };
